@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f0f0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hard {
  background-color: #ff0000;
}
.medium {
  background-color: #ca903a;
}
.easy{
  background-color: #49da57;;
}

.max-w-90{
  max-width: 220px;
}

.w-max-600{
  max-width: 900px;
}

.max-w-60{
  max-width: 75px;
}

.max-h-160{
  max-height: 114px;
  min-height: 114px;
  max-width: 160px;
  min-width: 160px;
  
}

.white-font{
  color: white;
}
@media screen and (max-width: 700px){

  .max-w-mobile{
    width: 100%;
  }
  .mobile-flex-col{
    flex-direction: column;
  }
  .moblie-text-center{
    text-align: center;
  }

}

@media screen and (max-width: 820px){
  .mobile-center{
    justify-content: center;
  }
  .mobile-text-sm{
    font-size: 1.3rem;
    white-space: nowrap;
  }

  .mobile-hidden{
    display: none;
  }
}
.darken-gray{
  background-color: gray;
}
.min-vh{
  min-height: 85vh;
}

.my-pp{
  max-width: 200px;
  min-width: 200px;
  max-height: 200px;
  min-height: 200px;
  border-radius: 50%;
}